html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

/* NAVBAR */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 80; 
  pointer-events: none;
}

.sidebar.open + .overlay {
  opacity: 1;
  pointer-events: auto;
}

.sidebar {
  z-index: 90;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: -36vw;
  width: 36vw;
  height: 100%;
  background-color: #fd6232;
  transition: left 500ms ease;
}

.sidebar.open {
  left: 0;
}

.toggle-btn {
  height: 100%;
  width: 70px;
  position: absolute;
  left: 36vw;
  background-color: #fd6232;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  padding: 10px;
  transition: 500ms ease;
  font-family: "rama-gothic-m", sans-serif;
  letter-spacing: 0.1em;

  p{
    color: white;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

}


.sidebar.open .toggle-btn {
  left: 36vw;

  p{
    display: none;
  }
}

.nav ul {
  font-family: "rama-gothic-m", sans-serif;
  letter-spacing: 0.1vw;
  list-style-type: none;
  padding: 20px;
  padding-bottom: 0px;
}

.nav li {
  margin: 20px 0;
}

.nav li a {
  transition: 400ms ease;
  color: #fff;
  text-decoration: none;
  font-size: 35px;
}

.nav li.active a, .nav li a:hover {
  color: #027593; 
  background-color: white;
  padding: 3.5%;
  border-radius: 7px;
}

.social-media{
  display: flex;
}

.social-media path{
  transition: 400ms ease;
}

.social-media div:hover path{
  fill: #027593;
}

@media (max-width: 1024px) {
  .sidebar {
    top: -80vh;
    left: 0;
    height: 80vh;
    width: 100%;
    flex-direction: column;
    transition: top 500ms ease;
  }

  .sidebar.open {
    top: 0;
  }

  .toggle-btn {
    width: 100%;
    height: 70px;
    top: 80vh;
    left: 0;
  }

  .sidebar.open .toggle-btn {
    top: 77vh;
    left: 0;
  }

}
/* NAVBAR ENDS HERE*/

@font-face {
  font-family: "rama-gothic-m", sans-serif;
  font-weight: 600;
  font-style: normal;
}

section {
  cursor: default;
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: row;
  z-index: 2;
  overflow: hidden;
}

h1, h2, h3 {
  font-family: "rama-gothic-m", sans-serif;
  color: #fd5a28f3;
}

p{
  color: #535353;
}
section a {
  background: #fd5a28f3;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 1em;
  font-weight: 500;
  border-radius: 7px;
  border: none;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em rgb(158, 33, 24);
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  cursor: pointer;
}

.special{
  background: transparent;
  color: inherit;
  font-family: inherit;
  padding: 0;
  display: inline;
  box-shadow: none;
  overflow: hidden;
  position: relative;
  height: auto;
  cursor: pointer;
  text-decoration: underline;
  transition: 400ms ease;
}

.special:hover{
  opacity: 60%;;
}

section a .icon {
  background: white;
  text-decoration: none;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 7px;
  box-shadow: 0.1em 0.1em 0.6em 0.2em rgb(158, 33, 24);
  right: 0.3em;
  transition: all 0.3s;
}

section a:hover .icon {
  width: calc(100% - 0.6em);
}

section a .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: rgb(158, 33, 24);
}

section a:hover .icon svg {
  transform: translateX(0.1em);
}

section a:active .icon {
  transform: scale(0.95);
}


.left-top {
  width: 40%;
}

.right-bottom {
  width: 60%;
}

.image-slider {
  width: 100%; 
  overflow: hidden;
}

.image-slider img {
  width: 100%; 
  height: auto; 
  object-fit: cover;
}

.image-slider .photo {
  width: 100%; 
}

.photo-slider{
  height: 400px;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track{
  display: flex;
  width: calc(250px * 18);
  animation: scroll 40s linear infinite;
}

.slide-track:hover{
  animation-play-state: paused;
}

@keyframes scroll {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-250px * 9));
  }
}

@keyframes scroll8 {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-250px * 8));
  }
}


.slide{
  position: relative;
  height:200px;
  width: 250px;
  display: flex;
  justify-content: column;
  align-items: center;
  padding: 15px;
}

.slide img{
  cursor: default;
  width: 100%;
  border-radius: 7px;
  transition: opacity 400ms ease;
} 

.slide:hover div{
  opacity: 1;
}

.slide:hover img{
  opacity: 50%;
}

.slide div {
  position: absolute; 
  display: flex;
  justify-content: center;
  top: 50%;      
  left: 0;       
  width: 100%;     
  z-index: 2;     
  opacity: 0;
  transition: 400ms ease;
}


.logo{
  height: 8em;
}

h1{
  font-size: 3em;
}

h2{
  font-size: 2.5em;
}

h3 {
  font-size: 1.5em;
  letter-spacing: 0.03em;
}

a {
  text-decoration: none;
  font-size: 1em;
}

li {
  font-size: 1em;
}

p {
}


.underline-animation{
  display: inline-block;
  position: relative;
  color: #027593;
}
  
.underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  color: #027593;
  background-color: #027593;
  transform-origin: bottom right;
  transition: transform 400ms ease;
}
  
.underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media (max-width: 1024px) {
  section{
    flex-direction: column;
  }

  .left-top, .right-bottom{
    width: 100%;
  }

  .logo{
    height: 6em;
    padding-left: 5%;
  }
}

/* HOME PAGE */
.home-page, .our-mission, .testimonials, .ways-help{
  height: 100vh;
}

.home-page .logo{
  margin-left: 3%;
}

.home-page, .our-mission, .ways-help, .book{
  margin-left: 70px;
  width: calc(100% - 70px);
}

.grid{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .div1 { grid-area: 1 / 1 / 2 / 2; }
  .div2 { grid-area: 1 / 2 / 2 / 3; }
  .div3 { grid-area: 2 / 1 / 3 / 2; }
  .div4 { grid-area: 2 / 2 / 3 / 3; }
  .div5 { grid-area: 3 / 1 / 4 / 3; }

    .photo img {
      object-fit: fill;
      width: 100%;
      display: block; 
    }

}

 .call-to-action{
  margin-bottom: 25%;
}

.home-page, .donate-home{
  animation: fadein 2s ease;
}

.volunteer-home{
  animation: fadein 2s ease;
}

img{
  animation: fadein 2s ease;
}

.volunteer-home .photo-slider{
  animation: fadein 5s ease;
}

.home-page .right-bottom, .donate-home .right-bottom{
  transform: 400ms ease;
  animation: slide-left 3s ease-in-out; 
}

.home-page .left-top, .donate-home .left-top{
  animation: slide-right 3s ease-in-out; 
  transform: 400ms ease;
}

.our-mission .right-bottom, .ways-help .right-bottom, 
.impact-volunteer .right-bottom  {
  display: flex;
  flex-direction: row;
  justify-content: center;  
}

.home-page .right-bottom :is(p, h1),
.our-mission .left-top :is(p, h2),
.ways-help .left-top :is(p, h2),
.team .left-top :is(p, h2),
.impact-volunteer .left-top :is(p, h2){
  margin-left: 5%;
  width: 60%;
}

.our-mission .right-bottom :is(p, h2, h3){
  margin-left: 10%;
  width: 60%;
}

.our-mission .left-top{
  justify-content: start;
  padding-top: 30%;
}

.our-mission .left-top button{
  margin-top: 40px;
}


.our-mission div, .ways-help div, .testimonials, .impact-volunteer div {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-mission .right-bottom  {
  flex-direction: row;
}

.our-mission .left-top, .our-mission .right-bottom, 
.ways-help .left-top, .impact-volunteer .left-top{
  height: 100%;
}

.our-mission .right-bottom, .ways-help .left-top {
  background-color: rgb(0, 120, 145);
}

.our-mission .right-bottom :is(h2, h3, p),
.ways-help .left-top :is(h2, h3, p), .ways-help li {
  color: white;
}

.ways-help ul{
  width: 55%;
  gap: 1em;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
}

.next-to-each-other{
  display: flex;
  flex-direction: row;
  width: 80%;
}

.ways-help .right-bottom div, .impact-volunteer .right-bottom div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin-left: auto;
  position: relative;
  z-index: 2;
  color: white; /* Ensures text is white for better visibility */
}

.ways-help .right-bottom div::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.5); 
  z-index: -1;
  transition: 400ms ease;
}

.ways-help .right-bottom div:hover::before,
.impact-volunteer .right-bottom div:hover::before {
  background: rgba(0, 0, 0, 0);
}

.ways-help .right-bottom h3,
.impact-volunteer .right-bottom h3{
  transition: opacity 400ms ease;
  opacity: 1;  
  color: white;
}

.ways-help .right-bottom div:hover h3,
.impact-volunteer .right-bottom div:hover h3 {
  opacity: 0;
}

.testimonials{
  height: 100vh;
  #review-btn{
    background: black;
    border-radius: 40px;
    box-shadow: inset 0 0 1.6em -0.6em black;
    font-size: 0.85em;
    transition: 400ms ease;

    .icon{
      box-shadow: 0.1em 0.1em 0.6em 0.2em black;
      border-radius: 50%;
    }
    svg{
      fill:rgb(0, 120, 145);
    }
  }

  #review-btn:hover {
    opacity: 50%;

    .icon {
      width:  2.2em;
    }
  }
}

.book{
  margin-top: 5%;
  gap: 2em;
  background-color: #fd6232;
  

  .right-bottom{
    p, h2{
      color: white;
    }

    p{
      width: 60%;
    }

    a{
      margin-top: 3%;
      margin-bottom: 5%;
      width: 3em;
      background: white;
      color: rgb(158, 33, 24);
      box-shadow: inset 0 0 1.6em -0.6em rgb(170 170 170);

      .icon{
        background: white;
        box-shadow: 0.1em 0.1em 0.6em 0.2em rgb(205 205 205);
      }
    }
  } 
  
  .second{
    display: none;
  }
}

.left-top .end{
  display: flex;
  align-items: start;
  width: 55%;
}

.call-to-action{
  flex-direction: column;
  width: 100%;
  margin-top: 20%;
}

.get-involved{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40vh;
  width: 100%;
}

.get-involved .right-bottom{
 width:10%;
}

.get-involved p{
  width: 300px
}

.call-to-action .next-to-each-other{
  width: 100%;
  display: flex;
  justify-content:center;
  gap: 3%;
}

.call-to-action .next-to-each-other a{
  width: 5em;
}

.our-mission .accomplishments {
  width: 100%;
  color: white;
}

#second-p, #second{
  display: none;
}


#icon-talk{ 
  width: 15em; 
  height: 14em; 
}

@media (max-width: 1024px) {
  .our-mission, .testimonials, .get-involved, .ways-help{
    height: auto;
  }

  .home-page, .our-mission, .ways-help, .book{
    margin-left: 0px;
    width: 100%;
  }

  .home-page, .our-mission, .ways-help, .book, .testimonials, .call-to-action{
    margin-left: 0px;
  }

  p{
    font-size: 0.8em;
  }

  .home-page, .team, .donate-home, .volunteer-home, .projects-home{
    margin-left: 0;
    margin-top: 70px;
  }

  .home-page .logo{
    margin-left: 0;
  }
  
  .home-page .right-bottom{
    padding-left: 0;
  }
  
  .home-page .right-bottom :is(p, h1){
    margin-left: 9%;
    width: 70%;
  }
   
  .home-page {
    flex-direction: row;

  }

  .home-page .left-top {
    width: 50%;
  }
  
  .home-page .right-bottom {
    width: 50%;
  }
  
  .our-mission .left-top :is(p, h2),
  .ways-help .left-top :is(p, h2),
  .impact-volunteer .left-top :is(p, h2) {
    width: 80%;
  }

  .our-mission .left-top, .our-mission .right-bottom, .ways-help .left-top, 
  .team .left-top, .impact-volunteer .left-top {
    height: auto;
   }

  .ways-help .right-bottom div, .impact-volunteer .right-bottom div{
    height: 100%;
  }

  .our-mission .left-top{
    padding-top: 0;
    margin-bottom: 10%;
  }

  .our-mission .right-bottom{
    flex-direction: column;
    
    h3{
      font-size: 1.25em;
    }

    li{
      font-size: 0.8em;
    }
  }
  
  .our-mission a, .ways-help a{
    width: 6em;
    font-size: 0.65em;
  }

  .our-mission .special{
    font-size: 1em;
  }

  .our-mission h2 {
    margin-bottom: 0;
  }

  .our-mission .end, .ways-help .end{
    width: 76%;
    margin-bottom: 5em;
  }

  .our-mission .description :is(h3, h2, p){
    width: 70%;
    margin-left: 0;
  }

  .our-mission .description ul{
    display: flex;
    flex-direction: row;
    gap: 10%;
    width: 70%;
  }

  .slide-track:hover, .slide-track .slide:focus , 
  .volunteer-home .slide-track:hover, .volunteer-home .slide-track .slide:focus{
    animation-play-state:running;
  }

   #second{
    display: flex;
  }

  #first, .ways-help .right-bottom{
    display: none;
  }

  .ways-help .left-top, .testimonials, .call-to-action {
    padding-top: 7%;
    padding-bottom: 7%;
  }

  .extra .image-slider{
    height: 40vh;
    object-fit: contain;
  }

  .extra .image-slider img{
     width: 100%;
  }

  .logo{
    height: 5em;
  }
  
  h1{ 
    font-size: 2.5em;
  }    
 
  .call-to-action .next-to-each-other a {
    width: 5em;
    font-size: 0.75em;
    margin-top: 3em;
  }

  .book{
    gap: 0;

    h2{ 
      margin-bottom: 0;
    }
    .left-top{ 
      margin-left: 0;
    }
    .right-bottom{ 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      p{
        width: auto;
      }

      a{
        width: auto;
        font-size: 0.8em;
   
      }
    }
    .second{
      display: block;
    }
    .first{
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .testimonials {
    height: 90vh;
  }

  .call-to-action {
    height: auto;
  }

  .call-to-action .next-to-each-other a {
    margin-top: 0;
  }

  .logo{
    height: 4.5em;
  }

  h1, h2{ 
    font-size: 2em;
  }

  .home-page .left-top {
    display: none;
  }

  .home-page .right-bottom {
    width: 100%;
  }
  
  .get-involved{
    flex-direction: column;
  }

  #first-p{
    display: none;
  }

  #second-p{
    display: block;
  }
  
  .call-to-action .next-to-each-other{
    margin-top: 10%;
  }

  #icon-talk{ 
    width: 10em; 
    height: 10em; 
  }
}

/* HOME PAGE ENDS */

/* ABOUT PAGE */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes fadein{
  0%{
   opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.team {
  animation: fadein 2s ease;
  background-color: #027593;
  display: flex;
  flex-direction: column;
  padding-bottom: 3em;

  h2, p, h4{
    color: white;
  }

  h4 {
    margin: 0;
    width: 40%;
    font-weight: normal;
  }

}

.team .staff{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width:100%;

  .div1 { grid-area: 2 / 2 / 3 / 3; }
  .div2 { grid-area: 2 / 3 / 3 / 4; }
  .div3 { grid-area: 2 / 4 / 3 / 5; }
  .div4 { grid-area: 1 / 3 / 2 / 4; }

  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  
  div {
    position: relative;
    transition: 400ms ease;
  }

}

.about-mission {
  flex-direction: column;
}

.about-mission .left-top p, .donate-home p{
  width: 60%;
}

.ceo p{
  width: 80%;
}

.about-mission .left-top, .about .donate{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 7%;
  width: 100%;
}

.about .photo-slider{
  height: 300px;
}

.about .slide-track{
  animation: scroll-right 40s linear infinite;
}

.staff .hover-text {
  display: flex;
  position: absolute;
  top: 0; /* Position from the top */
  left: 0;
  right: 0; /* Stretch to right edge */
  bottom: 0; /* Stretch to bottom edge */
  background-color: rgb(2,117,147, 0.75);
  color: white;
  padding: 5px;
  /* For centering text */
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  transition: 400ms ease;
  opacity: 0;
}

.staff div:hover .hover-text {
  opacity: 1; /* Use flex for centering */
}

.staff {
  h3, h5, h6{
    margin: 0;
  }

  h3{
   color: white;
  }
} 

@keyframes scroll-right {
  0%{
    transform: translateX(calc(-250px * 9));
  }
  100%{
    transform: translateX(0);
  }
}

.about .slide{
  padding: 2px;
}

.about .slide div {
  top: 10%;      
}

.about .donate{
  background-color: #027593;
  height: 30vh;
  padding-bottom: 30px;
}

.about .donate h1{
  color: white;
}

.ceo .left-top, .ceo .right-bottom,
.donate-home .left-top, .donate-home .right-bottom{
  width: 50vw;
}

.ceo .right-bottom, .donate-home .right-bottom{
  margin-left: 100px;
}

.ceo img, .donate-home img{
  width: 100%;
  height: auto;
}

.ceo{
  margin-top: 5%;
  li{
    width: 80%;
    font-size: 0.8em;
  }
}

@media (max-width: 1024px) {  
  .ceo{
    flex-direction: row;
  }

  .team .staff{
    margin-left: 0;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 1 / 3 / 2 / 4; }
    .div4 { grid-area: 2 / 2 / 3 / 3; }
    .div5 { grid-area: 3 / 1 / 4 / 2; }
    .div6 { grid-area: 3 / 2 / 4 / 3; }
    .div7 { grid-area: 3 / 3 / 4 / 4; }
  }
  
  .staff .hover-text h5{
     font-size: 0.6em;
  }
  .staff .hover-text h6{
     font-size: 0.5em;
  }

  .team h4, .about-mission .left-top p {
    width: 80%;
    font-size: 0.8em;
  }  

  .about-mission h2{ 
    width: 80%;
  }
}

@media (max-width: 768px) {
  .ceo{
    flex-direction: column;
  }

  .ceo .left-top, .ceo .right-bottom,
  .donate-home .left-top, .donate-home .right-bottom{
    width: 100%;
  }

  .ceo .right-bottom{
    height: auto;
  }

}

/* ABOUT PAGE ENDS */
/*VOLUNTEER PAGE*/
.second .slide-track{
  animation: scroll-right 40s linear infinite;
}

.volunteer-home .slide-track:hover{
  animation-play-state: paused;
}

.volunteer-home {
  flex-direction: column;
  animation: fadein 2s ease;
}

.volunteer-home p{
  width: 50%;
  margin-bottom: 39.36px;
}

.volunteer-home .photo-slider{
  height: 14em;
}

.volunteer-home h1{
  margin-top: 39.36px;
}

.volunteer-home .second{
  margin-top: 39.36px;
}

.volunteer-home .slide:hover img{
  opacity: 1;
}

.impact-volunteer {
  margin-top: 7%;
  margin-bottom: 7%;
  flex-direction: column;

    ul, li{ 
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      height: auto;
      padding: 0;
    }

     li{ 
      flex-direction: column;
    }

  p{
    width: 60%;
  } 
 
  h4, h5 {
    color: #535353;
  }

  h5{
    font-weight: normal;
    font-size: 1em;
  }  

  h3 {
    font-size: 2em;
    margin:0;
  }
}

@media (max-width: 1024px) {
  .volunteer-home p{
    width: 80%;
  }

  .volunteer-home a{
    font-size: 0.8em;
    margin-bottom: 39.36px;
  }
}
@media (max-width: 768px) {
  .volunteer-home .photo-slider{
    height: 12em;
  }
  .volunteer-home .slide{
    height: 100px;
    width: 150px;
  }

  .impact-volunteer ul{ 
      flex-direction: column;
      height: auto;
   }
  
   .impact-volunteer li, .impact-volunteer p {
     width: 80%;
   }
   
   h5{
     width: 60%;
   }  
  
   .impact-volunteer a {
     font-size: 1em;
   }
   
   .impact-volunteer li{  
     padding-bottom: 10px;
     border-bottom: 0.5px solid rgb(2,117,147, 0.5);
   }
   
}


/*VOLUNTEER PAGE ENDS*/


/*PROJECTS PAGE*/
.project-home{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;

  .next-to-each-other{ 
    justify-content: center;
    gap: 1em;
    margin-top: 3em;
  }

  p{ 
    width: 60%;
    
  }

  
  img{
    object-fit: cover;
    height: 100%;
    width: 50%;
  }

}
.wheelchair{
  margin-left: 70px;
  .photo img{
    object-fit: contain;
    width: 100%;
  }

  .right-bottom{ 
    margin-left: 60px;
  
    p{
      width: 80%;
    }
    
    .underline-animation{
      display: flex;
      width: 10em;
      svg {
        object-fit: contain;
      }
    }
  }

  a{ 
    width: 7em;
    margin-top: 2em;
    font-size: 0.85em;
  }
}


.food {
  flex-direction: column;
  height: 60vh;

  .left-top{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 100%;
    margin-left:70px;
    height: 100%;
    .div1 { grid-area: 1 / 2 / 2 / 3; }
    .div2 { grid-area: 1 / 1 / 2 / 2; }

  img{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  
  div {
    position: relative;
    transition: 400ms ease;
  }
  }
}

.food-info{
  background-color: #027593;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3em;
  margin-bottom: 3em;

  p, h2{ 
    color: white;
  }

  p{
    width: 50%;
  }

  a{
    margin-top: 1em;
    font-size: 0.85em;
  }

  h4, h2, a{
    color: white;
    margin-bottom: 0;
  }

  .special{
    margin-bottom: 1em;
    margin-top: 0;
  }
}

.payment-methods{
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #027593;
  height: auto;
  padding-top: 1em;
  padding-bottom: 3em;
  h2{
    color: white;
  }
  .next-to-each-other{
    justify-content: center;
    gap: 3em;
  }

}


.walkers {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .div1 { grid-area: 1 / 1 / 2 / 2; }
  .div2 { grid-area: 1 / 2 / 2 / 3; }
  .div3 { grid-area: 2 / 1 / 3 / 2; }
  .div4 { grid-area: 2 / 2 / 3 / 3; }
}


@media (max-width: 1024px) {
  .food {
    margin-top: 5em;
    height: 20vh;
    .left-top {
      margin-left: 0;
    }
  }

  .food-info{
    margin-bottom: 1em;
    p{
      width: 80%;
    }
  }

  .wheelchair{
    margin-left: 0;
    margin-top: 10em;
    flex-direction: row;
  }

  .project-home{
    margin-top: calc(2em + 70px); 
    margin-bottom: 10em; 
    a{
      font-size: 0.7em;
    }
  }
}

@media (max-width: 768px) {
  .project-home{
    p{
      width: 80%;
    }
    img{
      width: 100%;
    }
  }
  .wheelchair{
    flex-direction: column;
    margin-top: 3em;
  }

  .walkers {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 1 / 3 / 2 / 4; }
    .div4 { grid-area: 1 / 4 / 2 / 5; }
  }

  .payment-methods{
    margin-bottom: 3em;
    .next-to-each-other{
      font-size: 0.85em;
      gap: 0.5em;
    }
  }
}

/*PROJECTS PAGE ENDS*/
/*DONATE PAGE*/

@keyframes scroll20 {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-15em * 20));
  }
}

.sponsors{
  flex-direction: column;
  height: auto;
  padding-bottom: 6em; 
  .photo-slider{
    height: auto;
  }

  .slide-track{
    width: calc(15em * 40);
    animation: scroll20 60s linear infinite;
  }

  h2, h4{
    margin: 0;
    color:#027593;
  }

  h2{
    font-size: 2em;
  }

  .slide{
    width: 15em; height: 15em;
    img{
      object-fit: contain;
      cursor: pointer;
    }
  }

  a h4:hover{
    background-color: #027593;
    color: white;
  }
}

.sponsors a{
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  height: auto;
  flex-direction: column;
  transition: 400ms ease;
 }

 .sponsors a h4{
  border: 1px solid #027593;
  padding: 1em;
  margin-top: 1em;
  transition: 400ms ease;
  border-radius: 7px;
}

.donate-home{ 
  height: 100vh;

  a{
    margin-top: 2em;
    font-size: 1em;
  }

  .next-to-each-other{ 
    gap: 1em;
  }
}

.items-donation{
   margin-top: 5em;
   display:flex;
   justify-content: center;
  .right-bottom{
    width: 50%;

    p, ul{ 
      width: 80%;
    }
    
    a{
      font-size: 1em;
      margin-top: 3em;
      border: 0;
    }
  }
}

@media (max-width: 1024px) {
  .donate-home{ 
    height: 80vh;
    flex-direction: column;
    .left-top, .right-bottom{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }
    a, p{
      font-size: 0.8em;
    }
    
    .next-to-each-other{
      justify-content: center;
      align-items: center;
    }
  }

    .items-donation{

      .right-bottom{
      width: 80%;
      padding-left: 1em;
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:start;

      p{ 
        width: 98%;
      }

      ul{
        width: 80%;
      }
    
      a{
        margin-top: 2em;
      }
    }
  }
  
  .items-donation{
    .right-bottom{
      p, ul{ 
        width: 90%;
      }
    }
  }
}

@media (max-width: 768px) {
    
  .donate-home p{
    width: 70%;
    height: 70vh;
  }

  .donate-home{
    margin-top: calc(7em + 70px);
  }

  .sponsors{
    .slide{
      object-fit: cover;
      height: 75%;
    }
    
    h2{
      font-size: 1.5em;
    }
    
    a{
      font-size: 0.8em;
    }
  }

  .items-donation .right-bottom{
    align-items:center;
  }
}
/*DONATE PAGE ENDS*/

@keyframes slide-right {
  0%{
    transform: translateX(-40%);
  }
  100%{
    transform: translateX(0);
  }
  
}

@keyframes slide-left {
  0%{
    transform: translateX(60%);
  }
  100%{
    transform: translateX(0);
  }
  
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}



:root {
  --slide-width: 104%; 
}

.slider-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45em;
  overflow: hidden;

  .slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 95%;
  }

  .slide {
    width: 100%;
    height: auto; 
    object-fit: contain;
    transition: opacity 0.5s ease-in-out;
  }
}

.slider-nav {
  display: flex;
  gap: 5px;
  margin-bottom: 5%;
}

.slider-nav button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 400ms ease;
}

.slider-nav svg{
  transition: 400ms ease;
}

.slider-nav button:hover svg {
  fill: #FD3628;
}

@media (max-width: 1024px){
  .slider-wrapper {
    width: 40em;
    
    .slide {
      width: 99%;
    }
  }
}

@media (max-width: 767px){
  :root {
    --slide-width: 104%; 
  }

  .slider-wrapper {
    width: 26em;
    
    .slide{
      padding: 10px
    }
  }
}

@media (max-width: 410px){
  .slider-wrapper {
    width: 22em;

    .slide {
      width: 98%;
    }
  }
}


#google_translate_element {
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  width: fit-content;
  display: block !important; 
  visibility: visible !important;
}

/* Customize the dropdown */
.goog-te-gadget .goog-te-combo {
  background-color: #027593;
  border: 1px solid #027593;
  border-radius: 7px;
  padding: 5px;
  color: white;
  font-size: 1.25em;
  transition: 400ms ease;
  display: block !important; 
  visibility: visible !important;
}

.goog-te-gadget .goog-te-combo:hover {
  border: 1px solid white;
  border-radius: 7px;
}

 /* Hide the Google branding */
.goog-te-gadget {
  color: transparent;
}
.goog-te-gadget span {
  display: none;
}

#google_translate_element ::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

#google_translate_element ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
}

#google_translate_element ::-webkit-scrollbar-thumb {
    background-color: #fd5a28f3; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
    border: 2px solid #f1f1f1; /* Border around the scrollbar thumb */
}

#google_translate_element ::-webkit-scrollbar-thumb:hover {
    background: #fd5a28f3; /* Color of the scrollbar thumb on hover */
}

/* For Firefox */
#google_translate_element {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #888 #f1f1f1; /* Thumb and track color */
}

/* Custom scrollbar for IE and Edge */
@supports (-ms-overflow-style: none) {

    #google_translate_element::-ms-scrollbar {
        width: 12px; /* Width of the scrollbar */
    }

    #google_translate_element::-ms-scrollbar-track {
        background: #f1f1f1; /* Background of the scrollbar track */
    }

    #google_translate_element::-ms-scrollbar-thumb {
        background-color: #fd5a28f3; /* Color of the scrollbar thumb */
        border-radius: 10px; /* Rounded corners */
        border: 2px solid #f1f1f1; /* Border around the scrollbar thumb */
    }

    #google_translate_element::-ms-scrollbar-thumb:hover {
        background: #fd5a28f3; /* Color of the scrollbar thumb on hover */
    }
}



