/*FOOTER */
.footer {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: row;
  margin-top: 200px;
  margin-left: 70px;
  padding-bottom: 6%;
  padding-top: 6%;
  background-color:rgb(0, 0, 0, 0.05);
  color: rgb(2,117,147);
}

#credit, #phone-numbers, #email{
  color: rgb(2,117,147);
  transition: color 200ms ease;
}

#phone-numbers, #email{
  font-size: 0.85em;
}

.phone-numbers{
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  gap: 0.25em;
  margin-top: 0.5em;
}

.footer .logo{
  height: 6em;
}

.footer .side1{
  width: 50%;
  margin-left: 5%;
}

.social-media {
  margin-left: 1%;
}

.social-media svg{
  height: 50%;
}

#address, #giullya{
  color: currentColor;
  text-decoration: underline;
  transition: color 200ms ease;
}

.footer #address{
  font-size: 0.8em; 
  margin-left: 2%;
}

.footer #address:visited, #giullya:visited{
  color: currentColor;
}

.footer #address:hover, #giullya:hover, .social-media div:hover path, #phone-numbers:hover, #email:hover {
  color: #fd5a28f3;
  fill: #fd5a28f3;
}

.footer .side1 p{
  width: 70%;
  margin-left: 2%;

}

.verse p{
  margin-top: 3em;
  color: #fd5a28f3;
}

.selections {
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  width: 50%;
}

.footer h4{
  color: #fd5a28f3;
}
.footer p{
  font-size: 13px;
}

.selections .first{
  display: flex;
  width: 100%;
}

.selections .first div{
  display: flex;
  flex-direction: column; 
  gap: 10px;
  font-size: 14px;
  width: 25%;
  cursor: pointer;
}

.links{
  display: inline-block;
  position: relative;
  color: #027593;
  transition: 400ms ease;
}

.links:hover{
  color: #fd5a28f3
}




@media (max-width: 1024px) {
  .footer .logo{
    margin-left: 0;
    padding-left: 0;
  }  

  .footer{ 
    padding-bottom: 2em;
  }

  .footer, .side1 {
    flex-direction: column;
    padding-left: 0.25em;
    margin-left: 0;
  }

  .footer .side1 p, .footer #address{
    width: 70vw;
  }

  .social-media svg{
    height: 1.5em;
    margin-bottom: 0.75em;
  }  

  .selections {
    justify-content: center;
    gap: 1em;
    width: 100%;
  }

  .selections .first{
    padding-left:8%;
  }

  .selections .first div{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .selections h4, .selections .links{
    font-size: 0.75em;
  }

  .footer #address{
    white-space: pre;
    margin-left: 0.25em;
  }
}

@media (max-width: 768px){
  #phone-numbers, #email{
    font-size: 0.60em;
  }
}

@media (max-width: 355px){
  .footer #address{
    white-space: normal;
  }
  
}

@media (max-width: 220px){
  .footer .logo{
    height: 4.5em;
  }
}